import React, { useState, useEffect, useRef, useCallback } from 'react';
import { marked } from 'marked';
import './ChatWidgetFrank.css';
import './translations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faCopy, faCheck, faFileWord, faQuestion, faChartLine, faShieldAlt, faExchangeAlt, faIndustry, faFilePdf, faHeadset, faExpand, faCompress } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

const Modal = ({ isVisible, onClose, children }) => {
  if (!isVisible) return null;
  return (
    <div className="modal-overlay-frank" onClick={onClose}>
      <div className="modal-content-frank" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close-btn-frank" onClick={onClose}>
          <FontAwesomeIcon icon={faX} />
        </button>
        {children}
      </div>
    </div>
  );
};

const ChatWidgetFrank2 = ({ language, themeColor = '#0082ba', hoverColor = '#0595d3' }) => {
  const [copyIcon, setCopyIcon] = useState(faCopy);
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const chatBodyRef = useRef(null);
  const chatWindowRef = useRef(null);
  const currentBotMessage = useRef("");
  const [isLargeChat, setIsLargeChat] = useState(true);
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [isWelcomeMessageModalOpen, setIsWelcomeMessageModalOpen] = useState(false);

  const handleToggleChatSize = () => {
    setIsLargeChat(!isLargeChat);
  };

  const copyLastBotMessageToClipboard = () => {
    const lastBotMessage = messages.slice().reverse().find(message => message.sender === 'bot');
    if (lastBotMessage) {
      const htmlContent = marked(lastBotMessage.text);
      const inlineStyledHtml = htmlContent
      const blob = new Blob([inlineStyledHtml], { type: 'text/html' });
      const clipboardItem = new ClipboardItem({ 'text/html': blob });
      navigator.clipboard.write([clipboardItem])
        .then(() => {
          setCopyIcon(faCheck);
          setTimeout(() => {
            setCopyIcon(faCopy);
          }, 1000);
        })
        .catch(err => {
          console.error('Could not copy HTML with inline styles to clipboard', err);
        });
    }
  };

  const modifyLinks = (htmlText) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlText, 'text/html');

    const links = doc.querySelectorAll('a');
    links.forEach(link => {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer');
    });

    return doc.body.innerHTML;
  };

  const renderMessage = (text) => {
    const rawHtml = marked.parse(text);
    return modifyLinks(rawHtml);
  };

  const fetchWelcomeMessages = useCallback((sessionId) => {
    const eventSource = new EventSource(`/api/welcome_message?session_id=${sessionId}&language=${language}`);
    let welcomeMessage = "";
    let isFirstChunk = true;

    const handleMessage = (event) => {
      const chunk = event.data;
      if (chunk !== 'end of response') {
        const parsedChunk = chunk.replace(/<newline>/g, '\n');
        welcomeMessage += parsedChunk;

        if (isFirstChunk) {
          setIsLoading(false);
          isFirstChunk = false;
        }

        setMessages(prevMessages => {
          if (prevMessages.length > 0 && prevMessages[0].sender === 'bot') {
            const updatedMessages = [...prevMessages];
            updatedMessages[0] = { ...updatedMessages[0], text: welcomeMessage };
            return updatedMessages;
          } else {
            return [{ text: welcomeMessage, sender: 'bot' }, ...prevMessages];
          }
        });
        setWelcomeMessage(welcomeMessage);
      }
    };

    const handleError = (error) => {
      console.log('EventSource closed');
      setIsLoading(false);
      eventSource.close();
    };

    eventSource.onmessage = handleMessage;
    eventSource.onerror = handleError;

    return () => {
      eventSource.close();
    };
  }, [language]);

  const generateSessionId = () => {
    const timestamp = new Date().getTime();
    const randomNum = Math.floor(Math.random() * 10000) + 1;
    return `${timestamp}-${randomNum}`;
  };

  const toggleChat = useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
      setTimeout(() => setIsVisible(true), 0);
      document.body.classList.add('no-scroll-frank');
      if (!isInitialized) {
        setIsInitialized(true);
      }
    } else {
      setIsVisible(false);
      setTimeout(() => setIsOpen(false), 500);
      document.body.classList.remove('no-scroll-frank');
    }
  }, [isOpen, isInitialized]);

  const sendMessage = async (messageText) => {
    const text = typeof messageText === 'string' ? messageText : inputMessage;
    const messageToSend = text && typeof text === 'string' ? text.trim() : "";

    if (messageToSend) {
      const userMessage = { text: messageToSend, sender: 'user' };
      setMessages(prevMessages => [...prevMessages, userMessage]);
      setInputMessage('');
      setIsLoading(true);
      currentBotMessage.current = "";

      try {
        const eventSource = new EventSource(`/api/widget_chat?user_input=${encodeURIComponent(messageToSend)}&session_id=${sessionId}&language=${language}`);

        eventSource.onmessage = (event) => {
          const chunk = event.data;
          if (chunk !== 'end of response') {
            setIsLoading(false);
            const parsedChunk = chunk.replace(/<newline>/g, '\n');
            currentBotMessage.current += parsedChunk;
            setMessages(messages => {
              const lastMessage = messages[messages.length - 1];
              if (lastMessage && lastMessage.sender === 'bot') {
                return [...messages.slice(0, -1), { ...lastMessage, text: currentBotMessage.current }];
              } else {
                return [...messages, { text: currentBotMessage.current, sender: 'bot' }];
              }
            });
          } else {
            eventSource.close();
          }
        };

        eventSource.onerror = (error) => {
          console.error('Error fetching response:', error);
          setMessages(prevMessages => [...prevMessages, { text: 'Failed to get response from server.', sender: 'bot' }]);
          setIsLoading(false);
          eventSource.close();
        };

        eventSource.addEventListener('end', () => {
          setMessages(messages => {
            const lastMessage = messages[messages.length - 1];
            if (lastMessage && lastMessage.sender === 'bot') {
              return [...messages.slice(0, -1), { ...lastMessage, text: currentBotMessage.current }];
            } else {
              return [...messages, { text: currentBotMessage.current, sender: 'bot' }];
            }
          });
          setIsLoading(false);
          eventSource.close();
        });

      } catch (error) {
        console.error('Error fetching response:', error);
        setMessages(prevMessages => [...prevMessages, { text: 'Failed to get response from server.', sender: 'bot' }]);
        setIsLoading(false);
      }
    }
  };

  const generateWordDocument = async (messages) => {
    try {
        const response = await fetch('/api/generate_word_document', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ messages }),
        });

        if (!response.ok) {
            throw new Error('Failed to generate document');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'ChatHistory.docx';
        document.body.appendChild(a);
        a.click();
        a.remove();
    } catch (error) {
        console.error('Error generating document:', error);
    }
  };

  useEffect(() => {
    let cleanup;
    const loadWelcomeMessages = () => {
      if (isOpen && isInitialized) {
        setIsLoading(true);
        setMessages([{ text: '', sender: 'bot' }]);
        cleanup = fetchWelcomeMessages(sessionId);
      }
    };

    loadWelcomeMessages();

    return () => {
      if (cleanup) cleanup();
    };
  }, [isOpen, isInitialized, language, sessionId, fetchWelcomeMessages]);

  useEffect(() => {
    const newSessionId = generateSessionId();
    setSessionId(newSessionId);
  }, []);

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .chat-widget-icon-frank { background-color: ${themeColor}; }
      .chat-header-frank { background-color: ${themeColor}; }
      .chat-footer-frank button-frank { background-color: ${themeColor}; }
      .widget-user-message-frank { background-color: ${themeColor}; }
      .loading-dots-frank div-frank { background-color: ${themeColor}; }
      .chat-footer-frank button-frank:hover { background-color: ${hoverColor}; }
    `;
    document.head.appendChild(style);

    const openTimer = setTimeout(() => {
      setIsOpen(true);
      setTimeout(() => {
        setIsVisible(true);
        setIsInitialized(true);
      }, 50);
    }, 500);

    return () => {
      document.head.removeChild(style);
      clearTimeout(openTimer);
    };
  }, [themeColor, hoverColor]);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages, isLoading]);

  const handleAdvisorRequest = () => {
    const message = "Ich möchte mit einem Berater sprechen";
    sendMessage(message);
  };

  return (
    <div className="chat-widget-container-frank">
      <div className="chat-widget-icon-frank" onClick={toggleChat}>💬</div>
      {isOpen && (
        <>
        <div className="chat-overlay-frank" onClick={toggleChat}></div>
        <div className={`chat-window-frank ${isVisible ? 'show-frank' : ''} ${isLargeChat ? 'large-chat' : 'small-chat'}`} ref={chatWindowRef}>
            <div className="chat-header-frank">
              <div className="chat-header-title-frank">
                <span className="chat-title-frank">Early Lion AI</span>
                <div className='close-word-container-frank'>
                  <div className="question-icon-frank">
                    <FontAwesomeIcon
                      icon={faChartLine}
                      onClick={() => setIsWelcomeMessageModalOpen(true)}
                      title="Portfolio Performance"
                    />
                  </div>
                  <div className="question-icon-frank">
                    <FontAwesomeIcon
                      icon={faHeadset}
                      onClick={handleAdvisorRequest}
                      title="Mit Berater sprechen"
                    />
                  </div>
                  <div className="question-icon-frank">
                    <FontAwesomeIcon
                      icon={faQuestion}
                      onClick={() => setIsModalOpen(true)}
                      title="Hilfe"
                    />
                  </div>
                  <div className="export-word-icon-frank">
                    <FontAwesomeIcon
                      icon={copyIcon}
                      onClick={() => copyLastBotMessageToClipboard()}
                      title="Letzte Antwort kopieren"
                    />
                  </div>
                  <div className="export-word-icon-frank">
                    <FontAwesomeIcon
                      icon={faFileWord}
                      onClick={() => generateWordDocument(messages.slice(1))}
                      title="Chatverlauf in Word exportieren"
                    />
                  </div>
                  <div className="resize-chat-frank">
                    <FontAwesomeIcon
                      icon={isLargeChat ? faCompress : faExpand}
                      onClick={handleToggleChatSize}
                      title={isLargeChat ? "Chat verkleinern" : "Chat vergrössern"}
                    />
                  </div>
                  <button className="close-btn-frank close-chat-widget-icon-frank"
                    onClick={toggleChat}>
                    <FontAwesomeIcon
                      icon={faX}
                      title="Chat schliessen"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="chat-body-frank" ref={chatBodyRef}>
              {messages.map((message, index) => (
                message.sender === 'bot' ? (
                  <div className={`widget-message-frank widget-${message.sender}-message-frank`}>
                    <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                      {renderMessage(message.text)}
                    </ReactMarkdown>
                  </div>
                ) : (
                  <div
                    key={index}
                    className={`widget-message-frank widget-${message.sender}-message-frank`}
                  >
                    {message.text}
                  </div>
                )
              ))}
              {isLoading && (
                <div className="loading-dots-frank">
                  <div></div><div></div><div></div>
                </div>
              )}
            </div>
            <div className="chat-footer-frank">
              <div className="powered-by-frank">
                <span className="powered-by-text-frank" onClick={() => window.open('https://www.thescreener.com/', '_blank')}>
                  Powered with data by theScreener
                </span>
              </div>
              <div className="input-send-container-frank">
                <input
                  type="text"
                  className={`chat-input-frank ${isLoading ? 'disabled-frank' : ''}`}
                  placeholder="Type your message..."
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  onKeyPress={(e) => !isLoading && e.key === 'Enter' && sendMessage()}
                  disabled={isLoading}
                />
                <button
                  className={`send-message-frank ${isLoading ? 'disabled-frank' : ''}`}
                  onClick={sendMessage}
                  disabled={!inputMessage.trim() || isLoading}
                >
                  <i className="widget-send-icon-frank">Send</i>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <Modal isVisible={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2>Earlylion AI Hilfe</h2>
        <p>Sie können Fragen stellen wie:</p>
        <h3><FontAwesomeIcon icon={faChartLine} className="help-icon-frank" /> Portfolios:</h3>
        <ul>
          <li>Wie ist die Performance von meinem Portfolio?</li>
        </ul>
        <h3><FontAwesomeIcon icon={faShieldAlt} className="help-icon-frank" /> Positionen:</h3>
        <ul>
          <li>Wie beurteilen die Experten das Risiko von ABB?</li>
          <li>Was sind die Top 5 Aktien des Stoxx 600?</li>
          <li>Ich suche nach guten Dividendentiteln, was kannst du mir empfehlen?</li>
        </ul>
        <h3><FontAwesomeIcon icon={faExchangeAlt} className="help-icon-frank" /> Alternativen:</h3>
        <ul>
          <li>Gib mir Alternativen zu Kering.</li>
        </ul>
        <h3><FontAwesomeIcon icon={faIndustry} className="help-icon-frank" /> Sektoren:</h3>
        <ul>
          <li>Gib mir Informationen zum Nahrungsmittel Sektor.</li>
        </ul>
        <h3><FontAwesomeIcon icon={faFilePdf} className="help-icon-frank" /> theScreener Reports:</h3>
        <ul>
          <li>Ich möchte den PDF Report von Roche sehen.</li>
        </ul>
      </Modal>
      <Modal isVisible={isWelcomeMessageModalOpen} onClose={() => setIsWelcomeMessageModalOpen(false)}>
        <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
          <div className="widget-message-frank custom-max-width widget-bot-message-frank">
            <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
              {renderMessage(welcomeMessage)}
            </ReactMarkdown>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ChatWidgetFrank2;
