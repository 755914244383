import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import "./translations.css";

const resources = {
    de: {
      translation: {
        "greeting": "Guten Tag,",
        "performance": "Portfolio Performance",
        "regionAllocation": "Regionen Analyse",
        "assetClassAllocation": "Asset Class Verteilung",
        "currencyAllocation": "Währungsanteile",
        'kpi': "KPI's YTD",
        'asset_under_management_mgr': 'Verwaltetes Vermögen',
        'net_new_asset_mgr': 'Netto Neugeld',
        'income_information_mgr': 'Einkommensanalyse',
        'new_clients_mgr': 'Neukunden im Jahr',
        'asset_under_management_adv': 'Verwaltetes Vermögen',
        'net_new_asset_adv': 'Netto Neugeld',
        'income_information_adv': 'Einkommensanalyse',
        'new_clients_adv': 'Neukunden im Jahr',
        'client_contact': 'Kundenkontakt',
        "openAll": "Alle öffnen",
        "closeAll": "Alle schliessen",
        "shortSummary": "Kurz",
        "longSummary": "Lang",
        "loadingSummary": "Deine Zusammenfassung wird geladen...",
        "loadingAudio": "Audio wird geladen",
        "customer_note": "Kunden Notiz",
        'date': 'Datum',
        'client': 'Kunde',
        'type': 'Typ',
        'attendees': 'Teilnehmer',
        'comment': 'Kommentar',
        'save': 'Speichern',
        'chat': 'Assistant Lion',
        'chat_welcome_message': '<h3>Wie kann ich Ihnen heute helfen?</h3><p>Ob Sie einen Überblick über die Leistung Ihrer Portfolios 📊 benötigen, spezifische Details zu einem bestimmten Kundenportfolio 🔍 oder hochwertige Analysteninsights zu einzelnen Anlagepositionen oder Branchen von <b><a href="https://rm.thescreener.com/home/login" target="_blank" class="hover-link">theScreener</a></b> wünschen – ich stehe Ihnen zur Verfügung. Teilen Sie mir einfach mit, welche Informationen Sie benötigen, und ich werde die erforderlichen Daten präzise und effektiv bereitstellen. 🚀</p>',
        'ask_question': 'Frage eingeben...',
        'reset-chat': 'Chat löschen',
        'chat-tab': 'Chat',
        'calendar-tab': 'Mein Kalender',
        'very-important-prompts': 'VIP',
        'load-response': 'Lade Antwort...',
        'load-calendar': 'Lade Kalendereinträge...',
        'description': 'Beschreibung',
        'no-events': 'Keine Kalendereinträge vorhanden.',
        'prepare-me-for-meeting': 'Bereite mich auf meinen Termin vor.',
        'telephone': 'Telefon',
        'on_site': 'Vor Ort'
      }
    },
    fr: {
      translation: {
        "greeting": "Bonjour,",
        "performance": "Performance du Portefeuille",
        "regionAllocation": "Analyse de la Région",
        "assetClassAllocation": "Analyse de la Catégorie d'Actifs",
        "currencyAllocation": "Analyse de la Devise",
        'kpi': "KPI's",
        'asset_under_management_mgr': 'Actifs gérés',
        'net_new_asset_mgr': 'Nouveaux actifs nets',
        'income_information_mgr': 'Analyse des revenus',
        'new_clients_mgr': 'Nouveaux clients',
        'asset_under_management_adv': 'Actifs gérés',
        'net_new_asset_adv': 'Nouveaux actifs nets',
        'income_information_adv': 'Analyse des revenus',
        'new_clients_adv': 'Nouveaux clients',
        'client_contact': 'Contact client',
        "openAll": "Ouvrir tout",
        "closeAll": "Fermer tout",
        "shortSummary": "Court",
        "longSummary": "Long",
        "loadingSummary": "Votre résumé est en cours de chargement...",
        "loadingAudio": "Chargement de l'audio en cours",
        "customer_note": "Note client",
        "date": "Date",
        "client": "Client",
        "type": "Type",
        "attendees": "Participants",
        "comment": "Commentaire",
        "save": "Enregistrer",
        'chat': 'Assistant Lion',
        'chat_welcome_message': "<h3>Comment puis-je vous aider aujourd'hui ?</h3><p>Que vous ayez besoin d'un aperçu des performances de vos portefeuilles 📊, de détails spécifiques sur un portefeuille client particulier 🔍 ou de précieuses analyses d'analystes sur des positions d'investissement individuelles ou des secteurs via <b><a href='https://rm.thescreener.com/home/login' target='_blank' class='hover-link'>theScreener</a></b> – je suis à votre disposition. Dites-moi simplement quelles informations vous nécessitez, et je fournirai les données requises avec précision et efficacité. 🚀</p>",
        'ask_question': 'Entrez une question...',
        'reset-chat': 'Réinitialiser le chat',
        'chat-tab': 'Chat',
        'calendar-tab': 'Mon Calendrier',
        'very-important-prompts': 'VIP',
        'load-response': 'Chargement de la réponse...',
        'load-calendar': 'Chargement des entrées du calendrier...',
        'description': 'Description',
        'no-events': 'Aucune entrée de calendrier disponible.',
        'prepare-me-for-meeting': 'Prépare-moi pour ma réunion.',
        'telephone': 'Téléphone',
        'on_site': 'Sur place'        
      }
    },
    en: {
      translation: {
        "greeting": "Hi,",
        "performance": "Portfolio Performance",
        "regionAllocation": "Region Analysis",
        "assetClassAllocation": "Asset Class Analysis",
        "currencyAllocation": "Currency Analysis",
        'kpi': "KPI's",
        'asset_under_management_mgr': 'Managed Assets',
        'net_new_asset_mgr': 'Net New Assets',
        'income_information_mgr': 'Income Analysis',
        'new_clients_mgr': 'New Clients',
        'asset_under_management_adv': 'Managed Assets',
        'net_new_asset_adv': 'Net New Assets',
        'income_information_adv': 'Income Analysis',
        'new_clients_adv': 'New Clients',
        'client_contact': 'Client Contact',
        "openAll": "Open All",
        "closeAll": "Close All",
        "shortSummary": "Short",
        "longSummary": "Long",
        "loadingSummary": "Your summary is loading...",
        "loadingAudio": "Loading audio",
        "customer_note": "Customer Note",
        "date": "Date",
        "client": "Client",
        "type": "Type",
        "attendees": "Attendees",
        "comment": "Comment",
        "save": "Save",
        'chat': 'Assistant Lion',
        'chat_welcome_message': "<h3>How can I assist you today?</h3><p>Whether you need an overview of your portfolio's performance 📊, specific details on a particular client portfolio 🔍, or high-quality analyst insights on individual investment positions or sectors from <b><a href='https://rm.thescreener.com/home/login' target='_blank' class='hover-link'>theScreener</a></b> – I am here to help. Just let me know what information you need, and I will provide the necessary data accurately and effectively. 🚀</p>",
        'ask_question': 'Enter a question...',
        'reset-chat': 'Clear chat',
        'chat-tab': 'Chat',
        'calendar-tab': 'My Calendar',
        'very-important-prompts': 'VIP',
        'load-response': 'Loading response...',
        'load-calendar': 'Loading calendar entries...',
        'description': 'Description',
        'no-events': 'No calendar entries available.',
        'prepare-me-for-meeting': 'Prepare me for my meeting.',
        'telephone': 'Telephone',
        'on_site': 'On site'   
      }
    },
    es: {
      translation: {
          "greeting": "¡Buen día,",
          "performance": "Rendimiento del Portafolio",
          "regionAllocation": "Análisis de Regiones",
          "assetClassAllocation": "Distribución de Clase de Activos",
          "currencyAllocation": "Distribución de Monedas",
          'kpi': "KPI's YTD",
          'asset_under_management_mgr': 'Activos Bajo Gestión',
          'net_new_asset_mgr': 'Nuevos Activos Netos',
          'income_information_mgr': 'Análisis de Ingresos',
          'new_clients_mgr': 'Nuevos Clientes en el Año',
          'asset_under_management_adv': 'Activos Bajo Gestión',
          'net_new_asset_adv': 'Nuevos Activos Netos',
          'income_information_adv': 'Análisis de Ingresos',
          'new_clients_adv': 'Nuevos Clientes en el Año',
          'client_contact': 'Contacto con el Cliente',
          "openAll": "Abrir todo",
          "closeAll": "Cerrar todo",
          "shortSummary": "Corto",
          "longSummary": "Largo",
          "loadingSummary": "Tu resumen se está cargando...",
          "loadingAudio": "Cargando audio",
          "customer_note": "Nota del Cliente",
          'date': 'Fecha',
          'client': 'Cliente',
          'type': 'Tipo',
          'attendees': 'Asistentes',
          'comment': 'Comentario',
          'save': 'Guardar',
          'chat': 'Assistant Lion',
          'chat_welcome_message': '<h3>¿Cómo puedo ayudarle hoy?</h3><p>Ya sea que necesite un resumen del rendimiento de sus carteras 📊, detalles específicos sobre un portfolio de cliente específico 🔍, o insights analíticos de calidad sobre posiciones de inversión individuales o sectores a través de <b><a href="https://rm.thescreener.com/home/login" target="_blank" class="hover-link">theScreener</a></b> – estoy a su disposición. Simplemente indíqueme qué información requiere y proporcionaré los datos necesarios de manera precisa y efectiva. 🚀</p>',
          'ask_question': 'Ingresar pregunta...',
          'reset-chat': 'Eliminar chat',
          'chat-tab': 'Chat',
          'calendar-tab': 'Mi Calendario',
          'very-important-prompts': 'VIP',
          'load-response': 'Cargando respuesta...',
          'load-calendar': 'Cargando entradas del calendario...',
          'description': 'Descripción',
          'no-events': 'No hay entradas de calendario disponibles.',
          'prepare-me-for-meeting': 'Prepárame para mi reunión.',
          'telephone': 'Teléfono',
          'on_site': 'En el sitio'
      },
    },
    it: {
      translation: {
        "greeting": "Buongiorno,",
        "performance": "Performance del Portfolio",
        "regionAllocation": "Analisi Regionale",
        "assetClassAllocation": "Distribuzione per Classe di Asset",
        "currencyAllocation": "Composizione Valutaria",
        'kpi': "KPI's YTD",
        'asset_under_management_mgr': 'Patrimonio Gestito',
        'net_new_asset_mgr': 'Nuovi Patrimoni Netto',
        'income_information_mgr': 'Analisi dei Redditi',
        'new_clients_mgr': "Nuovi Clienti nell'Anno",
        'asset_under_management_adv': 'Patrimonio Gestito',
        'net_new_asset_adv': 'Nuovi Patrimoni Netto',
        'income_information_adv': 'Analisi dei Redditi',
        'new_clients_adv': "Nuovi Clienti nell'Anno",
        'client_contact': 'Contatto Cliente',
        "openAll": "Apri Tutto",
        "closeAll": "Chiudi Tutto",
        "shortSummary": "Breve",
        "longSummary": "Lungo",
        "loadingSummary": "Caricamento del tuo riassunto in corso...",
        "loadingAudio": "Caricamento audio",
        "customer_note": "Nota del Cliente",
        'date': 'Data',
        'client': 'Cliente',
        'type': 'Tipo',
        'attendees': 'Partecipanti',
        'comment': 'Commento',
        'save': 'Salva',
        'chat': 'Assistant Lion',
        'chat_welcome_message': '<h3>Come posso aiutarti oggi?</h3><p>Se hai bisogno di una panoramica sulla performance dei tuoi portafogli 📊, dettagli specifici su un particolare portafoglio cliente 🔍 o informazioni di analisi di qualità su singole posizioni di investimento o settori da <b><a href="https://rm.thescreener.com/home/login" target="_blank" class="hover-link">theScreener</a></b> – sono a tua disposizione. Dicci semplicemente quali informazioni ti servono e fornirò i dati necessari in modo preciso ed efficace. 🚀</p>',
        'ask_question': 'Inserisci una domanda...',
        'reset-chat': 'Cancella chat',
        'chat-tab': 'Chat',
        'calendar-tab': 'Il mio calendario',
        'very-important-prompts': 'VIP',
        'load-response': 'Carico risposta...',
        'load-calendar': 'Caricamento appuntamenti del calendario...',
        'description': 'Descrizione',
        'no-events': 'Nessun evento in calendario.',
        'prepare-me-for-meeting': 'Preparami per il mio appuntamento.',
        'telephone': 'Telefono',
        'on_site': 'Sul posto'
      }
    }  
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "de",
    fallbackLng: "de",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
