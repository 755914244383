import React, { useState, useEffect } from 'react';
import './ExpandableClient.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import './translations';
import { useTranslation } from 'react-i18next';


function ExpandableClient({ title, icon, expanded }) {
    const today = new Date().toISOString().slice(0, 10);
    const [date, setDate] = useState(today);
    const [isOpen, setIsOpen] = useState(false);
    const [client, setClient] = useState('Client 1');
    const [type, setType] = useState('');
    const [attendees, setAttendees] = useState('');
    const [kommentar, setKommentar] = useState('');

    const toggle = () => setIsOpen(!isOpen);

    const handleSave = () => {
        setType('');
        setAttendees('');
        setKommentar('');
        setDate(today);
    };

    const { t } = useTranslation();
    useEffect(() => {
        setIsOpen(expanded);
    }, [expanded]);

    const handleDateChange = (event) => {
        setDate(event.target.value);
        console.log("Selected Date:", event.target.value);
    };

    return (
        <div className="expandable-container">
            <div className="expandable-header" onClick={toggle}>
                <span>
                <FontAwesomeIcon
                    icon={icon}
                    className='expandable-icon'
                />
                {` ${title}`}
                </span>
                <FontAwesomeIcon
                    icon={isOpen ? faAngleUp : faAngleDown}
                    className="arrow"
                />
            </div>
            <div className={`tab-content ${isOpen ? 'open' : ''}`}>
                {isOpen && (
                    <>
                        <div className="tab-buttons-client">
                            <div className="new-note-container">
                                <div className="new-note-input">
                                    <label htmlFor="dateInput" style={{fontSize: '14px', fontFamily: '"Work Sans", sans-serif'}}>{t("date")}</label>
                                    <input
                                        type="date"
                                        id="dateInput"
                                        value={date}
                                        onChange={handleDateChange}
                                        style={{
                                            border: '1px solid #6a6a6a',
                                            borderRadius: '8px',
                                            display: 'block',
                                            width: '45%',
                                            boxSizing: 'border-box',
                                            marginTop: '5px',
                                            outline: 'none',
                                            color: '#000060',
                                            fontSize: '14px',
                                            backgroundColor: 'transparent',
                                            fontWeight: '700',
                                            padding: '5px'
                                        }}
                                    />
                                </div>
                                <div className="new-note-input">
                                    <label htmlFor="kundenzufriedenheit" style={{ fontSize: '14px'}}>{t('client')}</label>
                                    <div className="select-wrapper" style={{
                                        padding: '16px',
                                        boxSizing: 'border-box',
                                        borderRadius: '8px',
                                        border: '1px solid #6a6a6a',
                                        outline: 'none',
                                        marginTop: '5px',
                                        position: 'relative',
                                    }}>
                                        <select
                                            id="kundenzufriedenheit"
                                            value={client}
                                            onChange={e => setClient(e.target.value)}
                                            style={{
                                                backgroundColor: 'transparent',
                                                border: 'None',
                                                outline: 'None',
                                                width: '100%',
                                                height: '100%',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                fontSize: '14px',
                                                color: 'black'
                                            }}
                                        >
                                            <option value="de">Client 1</option>
                                            <option value="en">Client 2</option>
                                            <option value="fr">Client 3</option>
                                            <option value="fr">Client 4</option>
                                            <option value="fr">Client 5</option>
                                            <option value="fr">Client 6</option>
                                            <option value="fr">Client 7</option>
                                            <option value="fr">Client 8</option>
                                            <option value="fr">Client 9</option>
                                            <option value="fr">Client 10</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="new-note-input">
                                    <label htmlFor="kundenzufriedenheit" style={{ fontSize: '14px'}}>{t('type')}</label>
                                    <div className="select-wrapper" style={{
                                        padding: '16px',
                                        boxSizing: 'border-box',
                                        borderRadius: '8px',
                                        border: '1px solid #6a6a6a',
                                        outline: 'none',
                                        marginTop: '5px',
                                        position: 'relative',
                                    }}>
                                        <select
                                            id="kundenzufriedenheit"
                                            value={type}
                                            onChange={e => setType(e.target.value)}
                                            style={{
                                                backgroundColor: 'transparent',
                                                border: 'None',
                                                outline: 'None',
                                                width: '100%',
                                                height: '100%',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                fontSize: '14px',
                                                color: 'black'
                                            }}
                                        >
                                            <option value="1">{t('on_site')}</option>
                                            <option value="2">{t('telephone')}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="new-note-input">
                                    <label htmlFor="gespraechspartner" style={{ fontSize: '14px' }}>{t('attendees')}</label>
                                    <input
                                        type="text"
                                        id="gespraechspartner"
                                        value={attendees}
                                        onChange={e => setAttendees(e.target.value)}
                                        style={
                                            {
                                                width: '100%',
                                                padding: '8px',
                                                boxSizing: 'border-box',
                                                borderRadius: '8px',
                                                border: '1px solid #6a6a6a',
                                                outline: 'None',
                                                marginTop: '5px',
                                                fontSize: '14px',
                                                color: 'black'
                                            }
                                        }
                                    />
                                </div>
                                <div className="new-note-input">
                                    <label htmlFor="kommentar" style={{ fontSize: '14px' }}>{t('comment')}</label>
                                    <textarea
                                        id="kommentar"
                                        value={kommentar}
                                        onChange={e => setKommentar(e.target.value)}
                                        rows='4'
                                        style={
                                            {
                                                width: '100%',
                                                padding: '8px',
                                                boxSizing: 'border-box',
                                                borderRadius: '8px',
                                                border: '1px solid #6a6a6a',
                                                outline: 'None',
                                                marginTop: '5px',
                                                fontFamily: "Work Sans, sans-serif",
                                                fontSize: '14px',
                                                color: 'black'
                                            }
                                        }
                                    ></textarea>
                                </div>
                                <button
                                    className="new-note-save"
                                    onClick={handleSave}
                                >
                                    {t('save')}
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default ExpandableClient;
